<template>
  <div class="wrapper">
    <div class="card text-center">
      <div>
        <img src="@/assets/img-error.svg" />
      </div>
      <div class="mt-3" style="font-size: 18px;">
        {{ $t('404.note') }}
      </div>
      <div class="mt-3" style="color: #686E74">
        {{ $t('404.error') }}
      </div>
      <div class="mt-4">
        <button :disabled="status" class="btn btn-solid btn-blue" @click="home">{{ $t('goto_owlting_page') }}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'owlting.404',
  data () {
    return {
      status: false,
      url: 'https://www.owlting.com'
    }
  },
  methods: {
    home () {
      this.status = true
      window.location.href = this.url
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 850px) {
  .card {
    margin: 0 16px 0 16px;
    box-shadow: 0px 15px 30px -10px rgba(92, 101, 107, 0.15);
  }
}
</style>
